@import '~factor/src/scss/variables';
@import '~factor/src/scss/mixins';
@import 'src/scss/variables';

.file {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.label {
  margin-bottom: .5rem;
  color: $silver-chalice;
}

.row {
  display: flex;
  align-items: center;
}

.preview {
  position: relative;
  margin-right: 2rem;
  min-width: 60/16+rem;
  min-height: 1.5rem;
  background: $alto;
  line-height: 0;

  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 13/16+rem;

    svg {
      width: 100%;
      height: auto;
    }
  }

  img {
    position: relative;
    max-width: 100%;
  }
}

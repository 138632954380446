%button {
  padding: 0;
  border: 0;
  border-radius: 0;
  background: none;

  &:focus {
    outline: none;
  }
}

@import '~factor/src/scss/variables';
@import '~factor/src/scss/mixins';

.container {
  flex-grow: 1;
  padding-top: 1.5rem;
  padding-bottom: 64/16+rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20/16+rem;
}

.option {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: .5rem;
  }

  &Checkbox {
    min-width: 10rem;
  }

  &Processing {
    flex: 1 1 auto;
  }

  &Fee {
    margin-left: .5rem;
    min-width: 3rem;
    text-align: right;
  }
}

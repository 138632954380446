//almost for panda-components table cell width
@mixin flex-width($width) {
  flex: 0 1 $width/16+rem;
  min-width: $width/16+rem;
  max-width: $width/16+rem;
}

// td width
@mixin td-width($min, $max) {
  min-width: $min/16+rem;
  width: $max/16+rem;
  max-width: $max/16+rem;
}

// TYPOGRAPHY
%text {
  @include f1-400();
  font-size: 14/16+rem;
  line-height: 17/14+em;
}

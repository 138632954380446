body {

  .select {
    width: 100%;

    &._opened {
      position: relative;
      z-index: 20;
    }

    &__wrapper {
      position: static;
    }
  }
}

@import '~factor/src/scss/variables';
@import '~factor/src/scss/mixins';
@import 'src/scss/variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;

  label {
    margin-bottom: 4/16+rem;
    color: $silver-chalice;
  }

  textarea {
    width: 100%;
    min-height: 94/16+rem;
    @include placeholder($gray, 1);
    padding: .5rem;
    max-width: 100%;
    min-width: 100%;
    height: 4rem;
    max-height: 4rem;
    border: 1/16+rem solid $alto-1;
    border-radius: 9/16+rem;
    background: #fff;
    @include f1-400();
    font-size: 14/16+rem;
    line-height: 17/14+em;
    color: #000;

    &:focus {
      outline: none;
    }
  }
}

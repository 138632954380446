@import '~factor/src/scss/variables';
@import '~factor/src/scss/mixins';

.container {
  flex-grow: 1;
  padding-top: 1.5rem;
  padding-bottom: 64/16+rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20/16+rem;
}

.logo {
  display: flex;
  align-items: center;

  &Info {
    margin-left: 2rem;
  }
}

.filePreview {
  width: 124/16+rem;
  min-height: 50/16+rem;
}

.uploadBtn {
  display: flex;
  align-items: center;

  i {
    margin-right: .5rem;
    width: 13/16+rem;
    line-height: 0;
  }

  svg {
    width: 100%;
    height: auto;
  }

  path {
    fill: $conflower-blue;
    transition: fill .25s;
  }

  &:hover {

    path {
      fill: $indigo;
    }
  }
}

.p {
  font-size: .75rem;
  line-height: 15/12+em;
  color: $mine-shaft;
}

.banners {
  display: flex;
  align-items: flex-start;
}

.banner {
  display: flex;
  align-items: flex-start;

  &:not(:last-child) {
    margin-right: 2rem;
  }

  &Preview {
    border: 1/16+rem solid $dusty-gray;
    background: $alto;
  }
}

.color {
  display: flex;
  align-items: center;

  i {
    margin-right: .75rem;
    width: 20/16+rem;
    height: 20/16+rem;
    border-radius: 50%;
  }

  span {
    font-size: .75rem;
    line-height: 15/12+em;
    color: $mine-shaft;
  }
}

.colors {

  &Label {
    margin-bottom: .75rem;
  }

  &Item {
    margin-bottom: 1rem;
  }
}

.url {

  &:not(:last-child) {
    margin-bottom: 20/16+rem;
  }
}

.copyrightText {

  label {
    @include f1-700();
    font-size: .75rem;
    line-height: 15/12+em;
    color: $mine-shaft;
  }
}

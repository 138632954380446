body {

  .nav-bar {

    &__logo {

      svg {
        width: auto;
        height: 100%;
      }
    }

    &__wrapper-right {
      margin-left: 0;
    }
  }
}

@import '../../scss/external/bootstrap';
@import '~factor/dist/fonts.css';
@import '~factor/src/scss/variables';
@import '~factor/src/scss/mixins';
@import '../../scss/variables';
@import '../../scss/select';
@import '../../scss/block';
@import '../../scss/typography';
@import '../../scss/nav-bar';

* {
  box-sizing: border-box;
}

html {
  margin: 0;
  font-size: 16px;
}

body {
  @extend %text;
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.main {
  display: flex;
  padding: 0;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
}

@import '~factor/src/scss/variables';
@import '~factor/src/scss/mixins';

.tabs {

  &:global {

    &.tabs {
      z-index: 1;
      margin: 0 1rem;

      .tabs {

        &__btn {
          line-height: 40/16+em;
        }
      }
    }
  }
}

// COLORS
$mine-shaft-1: #1f1f1f;

$fog: #dce1ff;

// OLD
$titan-white: #ebeeff;
$alto-2: #d1d1d1;
$mercury: #e8e8e8;
$dove-gray-1: #696969;
$tundora: #4c4c4c;

$manhattan: #f7ce85;

$titan-white: #ebeeff;
$dodger-blue-1: #39f;
$blue-ribbon: #2c47f7;
$jacksons-purple: #1c2b89;

$jungle-green: #28bc97;
$aqua-forest-1: #4e9e7d;
$sherwood-green: #013627;

$stiletto: #9b3434;

//breakpoints
$xs: 480;
$sm: 768;
$md: 992;
$lg: 1200;
$xlg: 1280;

@import '~factor/src/scss/variables';
@import '~factor/src/scss/mixins';
@import 'src/scss/variables';
@import 'src/scss/button';

.container {

  &:global {

    &._disabled {

      :local {

        .title {
          opacity: .3;
        }

        .all,
        .checkbox {

          :global {

            .checkbox {

              &__label {
                cursor: default;

              }
            }
          }
        }
      }
    }
  }
}

.all {
  margin-bottom: .25rem;
}

.item {
  margin-left: 1.5rem;
  margin-bottom: .25rem;
}

.text {
  @include f1-400();
  font-size: 14/16+rem;
  line-height: 17/14+em;
}

.text-1 {
  @include f1-400();
  font-size: 17/16+rem;
  line-height: 21/16+rem;
  color: #000;
}

.title-3 {
  @include f1-900();
  font-size: 23/16+rem;
  line-height: 28/23+em;
  color: #000;
}

.title-4 {
  @include f1-700();
  font-size: 17/16+rem;
  line-height: 21/17+em;
  color: #000;
}

.label-2 {
  font-size: .75rem;
  line-height: 15/12+em;
  color: $mine-shaft;
}
